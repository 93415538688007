import * as React from "react";
import Layout from "../Layout/MasterLayout/MasterLayout";
import Button from "../components/Button/Button";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBTable,
	MDBTableHead,
	MDBTableBody,
} from "mdbreact";
import BannerCarousel from "../components/BannerCarousel/BannerCarousel";
import SEO from "../components/SEO/SEO";

import imgBeneficios from "../assets/images/beneficios/beneficios.png";

export default function Beneficios() {
	return (
		<Layout>
			<SEO title="Benefícios" lang="pt-br"/>
			<MDBContainer>
				<MDBRow>
					<MDBCol
						col={12}
						md={6}
						className="order-1 order-sm-0 my-auto"
					>
						<MDBRow>
							<MDBCol
								col={12}
								md={12}
							>
								<h1 className="pageTitle fgprimary-gradient py-5">benefícios</h1>
							</MDBCol>
							<MDBCol>
								<h2 className="fgprimary-gradient">matrículas e rematrículas</h2>
							</MDBCol>
							<MDBCol className="pb-2 pt-4 py-md-4" md={12}>
								<Button
									type="institucional"
									to="/matriculas"
									text="Quero estudar na Escola de Aplicação Feevale"
								/>
							</MDBCol>
							<MDBCol md={12}>
								<Button
									type="institucional"
									to="/rematriculas"
									text="Quero fazer a rematrícula na Escola de Aplicação Feevale"
								/>
							</MDBCol>
						</MDBRow>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgBeneficios} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="text-center">
						<BannerCarousel bannerKey="58028DE1-19C1-4AD7-8B1E-A15648C57538" bannerLength="1" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h2 className="fgprimary-gradient h1 py-4 text-center">
							estudantes do terceirão da Escola de Aplicação Feevale tem
							desconto especial para ingressar na graduação.
						</h2>
					</MDBCol>
				</MDBRow>
				{/*<MDBRow>
					<MDBCol>
						<h4 className="fgsecondary-gradient">
							descontos para cursos organizados por créditos
						</h4>
					</MDBCol>
	</MDBRow>
				<MDBRow>
					<MDBCol>
						<MDBTable className="text-center">
							<caption className="font-italic text-right">
								Exceto os cursos de Medicina, Medicina Veterinária e
								Odontologia.
							</caption>
							<MDBTableHead>
								<tr>
									<th className="font-weight-bold font-italic">
										número de créditos contratados
									</th>
									<th className="font-weight-bold font-italic">
										% de desconto aplicado no primeiro semestre de matrícula na
										graduação
									</th>
								</tr>
							</MDBTableHead>
							<MDBTableBody>
								<tr>
									<td>20 créditos ou +</td>
									<td className="font-weight-bold">50%</td>
								</tr>
								<tr>
									<td>16 a 19 créditos</td>
									<td className="font-weight-bold">40%</td>
								</tr>
								<tr>
									<td>12 a 15 créditos</td>
									<td className="font-weight-bold">30%</td>
								</tr>
							</MDBTableBody>
						</MDBTable>
					</MDBCol>
				</MDBRow>*/}
				<MDBRow>
					<MDBCol>
						<h4 className="fgprimary-gradient">
							descontos para cursos feevale way flex
						</h4>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<MDBTable className="text-center">
							<MDBTableHead>
								<tr>
									<th className="font-weight-bold font-italic">
										cursos contratados por mensalidade (currículo 2021/01)
									</th>
									<th className="font-weight-bold font-italic">
										% de desconto aplicado no primeiro semestre de matrícula na
										graduação
									</th>
								</tr>
							</MDBTableHead>
							<MDBTableBody>
								<tr>
									<td className="text-center">
										Administração
										<br />
										Arquitetura e Urbanismo
										<br />
										Artes Visuais
										<br />
										Biomedicina
										<br />
										Ciência da Computação
										<br />
										Ciências Contábeis
										<br />
										Design
										<br />
										Comércio Exterior
										<br />
										Design de Animação
										<br />
										Design de Interiores
										<br />
										Direito
										<br />
										Design Gráfico
										<br />
										Educação Física
										<br />
										Engenharia Civil
										<br />
										Enfermagem
										<br />
										Engenharia Elétrica
										<br />
										Engenharia de Produção
										<br />
										Engenharia de Computação
										<br />
										Engenharia Química
										<br />
										Engenharia Mecânica
										<br />
										Engenharia Eletrônica
										<br />
										Fisioterapia
										<br />
										Farmácia
										<br />
										Estética e Cosmética
										<br />
										Gestão da Produção Industrial
										<br />
										Gastronomia
										<br />
										Gestão Financeira
										<br />
										Jornalismo
										<br />
										Jogos Digitais
										<br />
										Moda
										<br />
										Pedagogia
										<br />
										Nutrição
										<br />
										Pedagogia Educação Infantil
										<br />
										Pedagogia Anos Iniciais Ensino Fundamental
										<br />
										Publicidade e Propaganda
										<br />
										Produção Audiovisual
										<br />
										Psicologia
										<br />
										Sistemas de Informação
										<br />
										Quiropraxia
										<br />
										Relações Públicas
										<br />
										Turismo
										<br />
										<span className="font-italic small">
											para matrículas em no mínimo 2 módulos.
										</span>
										<br></br>
										<span className="font-italic small">
											Exceto para os cursos de Medicina, Medicina Veterinária e Odontologia
										</span>
									</td>
									<td className="font-weight-bold">20%</td>
								</tr>
								<tr>
									<td>Cursos digitais</td>
									<td className="font-weight-bold">10%</td>
								</tr>
							</MDBTableBody>
						</MDBTable>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol col={12} md={6} className="py-3 pb-md-0 pt-md-3">
						<Button
							type="institucional"
							to="https://way.feevale.br/cursos/"
							text="Conheça os nossos cursos de graduação"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="pb-md-0 pt-md-3">
						<Button
							type="institucional"
							to="https://www.feevale.br/s/conteudo/c84eb223-0a5c-4e39-8d6d-c43d8291dd54/Estrat%c3%a9gias_financeiras_Escola_2023.pdf"
							text="Confira o regulamento completo"
						/>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
